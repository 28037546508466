import {
	HomeOutlined,
	UserOutlined,
	UsergroupAddOutlined,
	SettingOutlined,
	FileDoneOutlined,
} from "@ant-design/icons";
import { APP_PREFIX_PATH } from "configs/AppConfig";
import {
	PermissaoGrupoUsuarioEnum,
	PermissaoUsuarioEnum,
	PermissaoGrupoSeguroEnum,
	PermissaoTipoVeiculoEnum,
	PermissaoEspecieVeiculoEnum,
	PermissaoComposicaoVeiculoEnum,
	PermissaoTipoSinistroEnum,
	PermissaoTipoDespesaSinistroEnum,
	PermissaoEstadoEnum,
	PermissaoCidadeEnum,
	PermissaoIntegranteEnum,
	PermissaoVistoriadorEnum,
	PermissaoSinistroEnum,
	PermissaoModeloVeiculoEnum,
	PermissaoMotoristaEnum,
	PermissaoPaisEnum,
	PermissaoVeiculoEnum,
	PermissaoDespesaSinistroEnum,
	PermissaoVendedorEnum,
	PermissaoPenalizacaoEnum,
	PermissaoMarcaVeiculoEnum,
	PermissaoVistoriaEnum,
} from "enum/permissao-enum";

const dashBoardNavTree = [
	{
		key: "dashboard",
		path: `${APP_PREFIX_PATH}/dashboard`,
		title: "dashboard",
		icon: HomeOutlined,
		breadcrumb: false,
		submenu: [],
	},
	{
		key: "lista-veiculo",
		path: `${APP_PREFIX_PATH}/lista-veiculo`,
		title: "lista-veiculo",
		icon: UserOutlined,
		breadcrumb: false,
		submenu: [],
		permissao: PermissaoVeiculoEnum.VISUALIZAR,
	},

	{
		key: "lista-vistoria",
		path: `${APP_PREFIX_PATH}/lista-vistoria`,
		title: "lista-vistoria",
		icon: FileDoneOutlined,
		breadcrumb: false,
		submenu: [],
		permissao: PermissaoVistoriaEnum.VISUALIZAR,
	},
	{
		key: "lista-sinistros",
		path: `${APP_PREFIX_PATH}/lista-sinistros`,
		title: "Sinistros",
		icon: HomeOutlined,
		breadcrumb: false,
		submenu: [],
		permissao: PermissaoSinistroEnum.VISUALIZAR,
	},
	{
		key: "financeiro",
		path: `${APP_PREFIX_PATH}/financeiro`,
		title: "financeiro",
		icon: HomeOutlined,
		breadcrumb: false,
		submenu: [],
		permissao: PermissaoDespesaSinistroEnum.VISUALIZAR,
	},
	{
		key: "lista-integrante",
		path: `${APP_PREFIX_PATH}/lista-integrante`,
		title: "lista-integrante",
		icon: HomeOutlined,
		breadcrumb: false,
		submenu: [],
		permissao: PermissaoIntegranteEnum.VISUALIZAR,
	},
	{
		key: "lista-integrante-veiculo",
		path: `${APP_PREFIX_PATH}/lista-integrante-veiculo`,
		title: "lista-integrante-veiculo",
		icon: HomeOutlined,
		breadcrumb: false,
		submenu: [],
		permissao: PermissaoIntegranteEnum.VISUALIZAR,
	},
	{
		key: "lista-usuarios",
		path: `${APP_PREFIX_PATH}/lista-usuarios`,
		title: "lista-usuarios",
		icon: UserOutlined,
		breadcrumb: false,
		submenu: [],
		permissao: PermissaoUsuarioEnum.VISUALIZAR,
	},
	{
		key: "lista-grupos-usuario",
		path: `${APP_PREFIX_PATH}/lista-grupos-usuario`,
		title: "lista-grupos-usuario",
		icon: UsergroupAddOutlined,
		breadcrumb: false,
		submenu: [],
		permissao: PermissaoGrupoUsuarioEnum.VISUALIZAR,
	},
	{
		key: "ferramentas",
		path: `${APP_PREFIX_PATH}/ferramentas`,
		title: "Ferramentas",
		icon: SettingOutlined,
		breadcrumb: false,
		submenu: [],
	},
];

const navigationConfig = [...dashBoardNavTree];

export default navigationConfig;
