import React, { lazy, Suspense, useEffect, useRef } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from "components/shared-components/Loading";
import { APP_PREFIX_PATH } from "configs/AppConfig";
import JwtAuthService from "services/JwtAuthService";
import MenuService from "services/MenuService";

export const AppViews = () => {
	let intervalId = useRef(null);

	const populateMenu = async () => {
		try {
			if (JwtAuthService.isLogged()) {
				await MenuService.populateMenuData();
			}
		} catch (e) {}

		intervalId.current = setInterval(async () => {
			if (JwtAuthService.isLogged()) {
				MenuService.populateMenuData();
			}
		}, 30000);
	};

	useEffect(() => {
		populateMenu();

		return () => {
			clearInterval(intervalId.current);
		};
	}, []);

	return (
		<Suspense fallback={<Loading cover="content" />}>
			<Switch>
				<Route
					path={`${APP_PREFIX_PATH}/dashboard`}
					component={lazy(() => import(`./dashboard`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/dados-conta`}
					component={lazy(() => import(`./dados-conta`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/lista-usuarios`}
					component={lazy(() => import(`./lista-usuarios`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/usuario/:id`}
					component={lazy(() => import(`./usuario`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/usuario`}
					component={lazy(() => import(`./usuario`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/lista-grupos-usuario`}
					component={lazy(() => import(`./lista-grupos-usuario`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/grupo-usuario/:id`}
					component={lazy(() => import(`./grupo-usuario`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/grupo-usuario`}
					component={lazy(() => import(`./grupo-usuario`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/lista-grupos-seguro`}
					component={lazy(() => import(`./lista-grupos-seguro`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/grupo-seguro/:id`}
					component={lazy(() => import(`./grupo-seguro`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/grupo-seguro`}
					component={lazy(() => import(`./grupo-seguro`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/lista-tipo-veiculo`}
					component={lazy(() => import(`./lista-tipo-veiculo`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/tipo-veiculo/:id`}
					component={lazy(() => import(`./tipo-veiculo`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/tipo-veiculo`}
					component={lazy(() => import(`./tipo-veiculo`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/lista-especie-veiculo`}
					component={lazy(() => import(`./lista-especie-veiculo`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/especie-veiculo/:id`}
					component={lazy(() => import(`./especie-veiculo`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/especie-veiculo`}
					component={lazy(() => import(`./especie-veiculo`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/lista-composicao-veiculo`}
					component={lazy(() => import(`./lista-composicao-veiculo`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/composicao-veiculo/:id`}
					component={lazy(() => import(`./composicao-veiculo`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/composicao-veiculo`}
					component={lazy(() => import(`./composicao-veiculo`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/lista-tipo-sinistro`}
					component={lazy(() => import(`./lista-tipo-sinistro`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/tipo-sinistro/:id`}
					component={lazy(() => import(`./tipo-sinistro`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/tipo-sinistro`}
					component={lazy(() => import(`./tipo-sinistro`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/lista-tipo-despesa-sinistro`}
					component={lazy(
						() => import(`./lista-tipo-despesa-sinistro`),
					)}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/tipo-despesa-sinistro/:id`}
					component={lazy(() => import(`./tipo-despesa-sinistro`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/tipo-despesa-sinistro`}
					component={lazy(() => import(`./tipo-despesa-sinistro`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/lista-estados`}
					component={lazy(() => import(`./lista-estados`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/estado/:id`}
					component={lazy(() => import(`./estado`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/estado`}
					component={lazy(() => import(`./estado`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/lista-cidades`}
					component={lazy(() => import(`./lista-cidades`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/cidade/:id`}
					component={lazy(() => import(`./cidade`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/cidade`}
					component={lazy(() => import(`./cidade`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/lista-integrante`}
					component={lazy(() => import(`./lista-integrante`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/integrante/:id`}
					component={lazy(() => import(`./integrante`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/detalhes-integrante/:id`}
					component={lazy(() => import(`./detalhes-integrante`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/integrante`}
					component={lazy(() => import(`./integrante`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/veiculo/:id`}
					component={lazy(() => import(`./veiculo`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/detalhes-veiculo/:id`}
					component={lazy(() => import(`./detalhes-veiculo`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/veiculo`}
					component={lazy(() => import(`./veiculo`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/lista-vistoriador`}
					component={lazy(() => import(`./lista-vistoriador`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/vistoriador/:id`}
					component={lazy(() => import(`./vistoriador`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/vistoriador`}
					component={lazy(() => import(`./vistoriador`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/lista-sinistros`}
					component={lazy(() => import(`./lista-sinistros`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/sinistro/:id`}
					component={lazy(() => import(`./sinistro`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/sinistro`}
					component={lazy(() => import(`./sinistro`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/lista-veiculo`}
					component={lazy(() => import(`./lista-veiculo`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/lista-modelo-veiculo`}
					component={lazy(() => import(`./lista-modelo-veiculo`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/modelo-veiculo/:id`}
					component={lazy(() => import(`./modelo-veiculo`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/modelo-veiculo`}
					component={lazy(() => import(`./modelo-veiculo`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/lista-vistoria`}
					component={lazy(() => import(`./lista-vistoria`))}
				/>
				{/*<Route*/}
				{/*	path={`${APP_PREFIX_PATH}/lista-motoristas`}*/}
				{/*	component={lazy(() => import(`./lista-motoristas`))}*/}
				{/*/>*/}
				{/*<Route*/}
				{/*	path={`${APP_PREFIX_PATH}/motorista/:id`}*/}
				{/*	component={lazy(() => import(`./motorista`))}*/}
				{/*/>*/}
				{/*<Route*/}
				{/*	path={`${APP_PREFIX_PATH}/motorista`}*/}
				{/*	component={lazy(() => import(`./motorista`))}*/}
				{/*/>*/}
				<Route
					path={`${APP_PREFIX_PATH}/lista-paises`}
					component={lazy(() => import(`./lista-paises`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/pais/:id`}
					component={lazy(() => import(`./pais`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/pais`}
					component={lazy(() => import(`./pais`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/lista-fornecedores`}
					component={lazy(() => import(`./lista-fornecedores`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/fornecedor/:id`}
					component={lazy(() => import(`./fornecedor`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/fornecedor`}
					component={lazy(() => import(`./fornecedor`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/financeiro`}
					component={lazy(() => import(`./financeiro`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/financeiro-rateio`}
					component={lazy(() => import(`./financeiro-rateio`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/lista-vendedores`}
					component={lazy(() => import(`./lista-vendedores`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/vendedor/:id`}
					component={lazy(() => import(`./vendedor`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/vendedor`}
					component={lazy(() => import(`./vendedor`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/lista-fipe`}
					component={lazy(() => import(`./lista-fipe`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/lista-penalizacoes`}
					component={lazy(() => import(`./lista-penalizacoes`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/penalizacao/:id`}
					component={lazy(() => import(`./penalizacao`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/penalizacao`}
					component={lazy(() => import(`./penalizacao`))}
				/>
				{/*<Route*/}
				{/*	path={`${APP_PREFIX_PATH}/lista-tipos-vistoria`}*/}
				{/*	component={lazy(() => import(`./lista-tipos-vistoria`))}*/}
				{/*/>*/}
				{/*<Route*/}
				{/*	path={`${APP_PREFIX_PATH}/tipo-vistoria/:id`}*/}
				{/*	component={lazy(() => import(`./tipo-vistoria`))}*/}
				{/*/>*/}
				<Route
					path={`${APP_PREFIX_PATH}/tipo-vistoria`}
					component={lazy(() => import(`./tipo-vistoria`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/ferramentas`}
					component={lazy(() => import(`./ferramentas`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/lista-marca-veiculo`}
					component={lazy(() => import(`./lista-marca-veiculo`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/marca-veiculo/:id`}
					component={lazy(() => import(`./marca-veiculo`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/marca-veiculo`}
					component={lazy(() => import(`./marca-veiculo`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/lista-integrante-veiculo`}
					component={lazy(() => import(`./lista-integrante-veiculo`))}
				/>
				<Redirect
					from={`${APP_PREFIX_PATH}`}
					to={`${APP_PREFIX_PATH}/dashboard`}
				/>
			</Switch>
		</Suspense>
	);
};

export default React.memo(AppViews);
